<template>
	<div class="pui-form">
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<imofalformsform-header v-model="model"></imofalformsform-header>
		</pui-form-header>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#imofalform3menu'">{{ $t('imofalform3.maintab') }}</v-tab>
				<v-tab :href="'#imofalform3items'" v-if="!isCreatingElement">{{ $t('imofalform3.imofalform3itemstab') }}</v-tab>
				<!-- <v-tab :href="'#imofalform3bunkers'" v-if="!isCreatingElement">{{ $t('imofalform3.imofalform3bunkerstab') }}</v-tab>			-->
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'imofalform3menu'">
					<v-row class="pui-form-layout">
						<v-col xs12 lg12>
							<v-row dense>
								<!-- portcall -->
								<v-col class="12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										v-if="!this.isCreatingElement"
										:id="`imofalform3-portcall`"
										attach="imofalform3-portcall"
										:label="$t('imofalform3.portcall')"
										disabled
										toplabel
										clearable
										v-model="model"
										modelName="vlupportcalls"
										:modelFormMapping="{ portcallid: 'portcallid' }"
										:itemsToSelect="[{ portcallid: model.portcallid }]"
										:itemValue="['portcallid']"
										:itemText="(item) => `${item.visitid}`"
										:order="{ portcallid: 'desc' }"
										:fixedFilter="filterPortcalls"
									></pui-select>
									<pui-select
										v-if="this.isCreatingElement"
										:id="`imofalform3-portcall`"
										attach="imofalform3-portcall"
										:label="$t('imofalform3.portcall')"
										:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid' || this.$route.params.parentpk"
										toplabel
										clearable
										v-model="model"
										:modelName="isCreatingElement ? 'vlupportcalls' : 'vlupportcalls'"
										:modelFormMapping="{ portcallid: 'portcallid' }"
										:itemsToSelect="[{ portcallid: model.portcallid }]"
										:itemValue="['portcallid']"
										:itemText="(item) => `${item.visitid}`"
										:order="{ portcallid: 'desc' }"
										:fixedFilter="filterPortcalls"
									></pui-select>
								</v-col>
								<v-col class="6 col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" style="padding-top: 20px; margin-left: 50px">
									<!-- isarrival -->
									<!--
									<pui-checkbox
										:id="`imofalform3-isarrival`"
										:label="$t('imofalform3.isarrival')"
										v-model="model.isarrival"
										true-value="1"
										false-value="0"
										:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
									></pui-checkbox>
									-->
								</v-col>
								<v-col class="6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="padding-top: 20px; margin-left: -50px">
									<!-- isdeparture -->
									<!--
									<pui-checkbox
										:id="`imofalform3-isdeparture`"
										:label="$t('imofalform3.isdeparture')"
										v-model="model.isdeparture"
										:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
										true-value="1"
										false-value="0"
									></pui-checkbox>
									-->

									<v-radio-group
										v-if="this.isCreatingElement"
										v-model="model.indarrivaldeparture"
										:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
										row
										style="position: relative; top: 4px"
									>
										<v-radio
											:label="$t('imofalform3menu.isarrival')"
											:checked="selectedOption === $t('imofalform3menu.isarrival')"
											:value="'1'"
										></v-radio>
										<v-radio
											:label="$t('imofalform1menu.isdeparture')"
											:checked="selectedOption === $t('imofalform3menu.isdeparture')"
											:value="'2'"
										></v-radio>
									</v-radio-group>
									<v-radio-group
										v-if="!this.isCreatingElement"
										v-model="model.indarrivaldeparture"
										disabled
										row
										style="position: relative; top: 4px"
									>
										<v-radio
											:label="$t('imofalform3menu.isarrival')"
											:checked="selectedOption === $t('imofalform3menu.isarrival')"
											:value="'1'"
										></v-radio>
										<v-radio
											:label="$t('imofalform1menu.isdeparture')"
											:checked="selectedOption === $t('imofalform3menu.isdeparture')"
											:value="'2'"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.ship')">
										<v-row dense>
											<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<!-- shipname -->
												<pui-text-field
													:id="`imofalform3-shipname`"
													v-model="model.portcall.mship.shipname"
													:label="$t('imofalform3.shipname')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<!-- imo -->
												<pui-text-field
													:id="`imofalform3-imo`"
													v-model="model.portcall.mship.imo"
													:label="$t('imofalform3.imo')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<!-- callsign -->
												<pui-text-field
													:id="`imofalform3-callsign`"
													v-model="model.portcall.mship.callsign"
													:label="$t('imofalform3.callsign')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform3-flag`"
													attach="imofalform3-flag"
													:label="$t('imofalform3.flag')"
													toplabel
													disabled
													clearable
													reactive
													v-model="model.portcall"
													modelName="mcountries"
													:modelFormMapping="{ countrycode: 'flag' }"
													:itemsToSelect="itemsToSelectFlag"
													:itemValue="['countrycode']"
													:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
													:order="{ countrydesc: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.voyage')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<!-- voyagenum -->
												<pui-text-field
													:id="`imofalform3-voyagenum`"
													v-model="model.portcall.voyagenum"
													:label="$t('imofalform3.voyagenum')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<!-- portofarrival -->
												<pui-select
													id="`imofalform3-portofarrival`"
													attach="imofalform3-portofarrival"
													:label="this.labelportarrival"
													toplabel
													v-model="model.portcall"
													modelName="mports"
													disabled
													reactive
													:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
													:itemsToSelect="itemsToSelectArrivalPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<!-- arrivaldate en la lupa de escala sera un ifelse. Si está la ata se coge esa, sino la eta -->
											<v-col v-if="this.model.isarrival === '1'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<!-- arrivaldate -->
												<pui-date-field
													:id="`imofalform3-arrivaldate`"
													:label="$t('imofalform3.arrivaldate')"
													toplabel
													time
													v-model="model.arrivaldate"
													disabled
												></pui-date-field>
											</v-col>
											<v-col
												v-if="this.model.isdeparture === '1'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<!-- departuredate -->
												<pui-date-field
													:id="`imofalform3-departuredate`"
													:label="$t('imofalform3.departuredate')"
													toplabel
													time
													v-model="model.departuredate"
													disabled
												></pui-date-field>
											</v-col>
											<v-col v-if="this.model.isarrival === '1'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform3-lastport`"
													attach="imofalform3-lastport"
													:label="$t('imofalform3.lastport')"
													toplabel
													reactive
													disabled
													v-model="model.portcall"
													modelName="mports"
													:modelFormMapping="{ countrycode: 'previouscountry', portcode: 'previousport' }"
													:itemsToSelect="itemToSelectPreviousPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col
												v-if="this.model.isdeparture === '1'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-select
													id="`imofalform3-nextport`"
													attach="imofalform3-nextport"
													:label="$t('imofalform3.nextport')"
													toplabel
													reactive
													v-model="model.portcall"
													disabled
													modelName="mports"
													:modelFormMapping="{ countrycode: 'nextcountry', portcode: 'nextport' }"
													:itemsToSelect="itemToSelectNextPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>

											<!-- NUMPERSONS -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`numpersons-imofalform3`"
													v-model="model.imofalform3.numpersons"
													:label="$t('imofalform3.numpersons')"
													:disabled="
														this.model.status == 'Submitted' || this.model.status == 'Invalid' || model.status == 'Sent'
													"
													required
													toplabel
													integer
													max="99999"
													min="1"
												></pui-number-field>
											</v-col>
											<!-- PERIODSTAY -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`periodstay-imofalform3`"
													v-model="model.imofalform3.periodstay"
													:label="$t('imofalform3.periodstay')"
													disabled
													toplabel
													integer
													min="0"
													max="99999"
												></pui-number-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>

							<v-row dense style="margin-top: -15px"> </v-row>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :value="'imofalform3items'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="imofalform3items-grid"
						:parentModelName="modelName"
						:parentPk="this.imofalform3idB64"
						:parentPkChildFk="{ imofalform3id: 'imofalform3id' }"
						:parentModel="model.imofalform3"
						:showMasterDetailFilterListBtn="true"
						:showMasterDetailFilterBtn="true"
						:showMasterDetailSortBtn="true"
						:showMasterDetailConfBtn="true"
						:formDisabled="this.model.status === this.statusSubmitted || this.model.status === 'Invalid'"
					></pui-master-detail>
				</v-tab-item>
				<!-- Page Footer -->
				<pui-form-footer>
					<nsw-imo-form-footer-btns
						:formDisabled="(this.model.status == 'Invalid' && !isCreatingElement) || !havePermissions(['WRITE_IMOFALFORM3ITEMS'])"
						:saveDisabled="saving"
						:value="model"
						:saveAndUpdate="saveAndUpdate"
						:showNext="isCreatingElement"
						:showDraft="!isCreatingElement && this.model.status !== this.statusSubmitted"
						:showSubmit="!isCreatingElement && this.model.status !== this.statusSubmitted"
						:showNewVersion="false"
						modelName="imofalform3menu"
						:save="save"
						:back="back"
					></nsw-imo-form-footer-btns>
				</pui-form-footer>
			</v-tabs-items>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { STATUS_SUBMITTED_DESC } from '../../constants/imofalforms/ImoFalFormsConstants';
import imofalform3Actions from './ImoFalForm3Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform3menu-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform3menu',
			actions: imofalform3Actions.actions,
			pkAttribute: 'imofalformid',
			modeldetail: true,
			tabmodel: 'imofalform3menu',
			diferenciaDias: null,
			labelportarrival: this.$t('imofalform3.portofarrival'),
			statusSubmitted: STATUS_SUBMITTED_DESC,
			imofalform3idB64: null,
			filtrado: ''
		};
	},
	mounted() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-imofalform3-portcall', (newVal) => {
			var lupaModel = newVal.model;
			if (this.isCreatingElement) {
				if (lupaModel !== null) {
					this.fillPortCallFields2(lupaModel);
				} else {
					this.clearPortCallFields(lupaModel);
				}
			} else {
				if (lupaModel !== null) {
					this.fillPortCallFields(lupaModel);
				} else {
					this.clearPortCallFields(lupaModel);
				}
			}
		});
	},
	computed: {
		filterPortcalls() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'portcallstatus', op: 'ne', data: 0 },
							{ field: 'portcallstatus', op: 'ne', data: 6 },
							{ field: 'portcallstatus', op: 'ne', data: 3 }
						]
					}
				]
			};
		},
		itemsToSelectShipType() {
			return [{ shiptypecode: this.model.portcall.mship.shiptypecode }];
		},
		itemsToSelectArrivalPort() {
			return [{ countrycode: this.model.portcall.countrycode, portcode: this.model.portcall.portcode }];
		},
		itemsToSelectFlag() {
			return [{ countrycode: this.model.portcall.mship.flag }];
		},
		itemToSelectPreviousPort() {
			return [{ countrycode: this.model.portcall.previouscountry, portcode: this.model.portcall.previousport }];
		},
		itemToSelectNextPort() {
			return [{ countrycode: this.model.portcall.nextcountry, portid: this.model.portcall.nextport }];
		}
	},
	watch: {
		'model.isarrival'() {
			if (this.model.isarrival === '1') {
				this.model.isdeparture = '0';
				this.model.indarrivaldeparture = '1';
				this.labelportarrival = this.$t('imofalform3.portofarrival');
			}
		},

		'model.isdeparture'() {
			if (this.model.isdeparture === '1') {
				this.model.isarrival = '0';
				this.model.indarrivaldeparture = '2';
				this.labelportarrival = this.$t('imofalform3.portofdeparture');
			}
		}
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			if (this.$route.params.parentpk) {
				this.model.portcallid = JSON.parse(atob(this.$route.params.parentpk)).portcallid;
			}

			this.calcularDiferencia();
			this.model.periodstay = this.diferenciaDias;
			//this.diferenciaDias = this.model.periodstay;
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				this.model.imofalformid = parentModel.pk.imofalformid;
			}
			const jsonimofalform3id = '{"imofalform3id":"' + this.model.imofalform3id + '"}';
			this.imofalform3idB64 = this.$puiUtils.utf8ToB64(jsonimofalform3id);
		},
		fillPortCallFields2(lupaModel) {
			this.model.portcall.mship.shipname = lupaModel.shipname;
			this.model.portcall.mship.shiptypecode = lupaModel.shiptypecode;
			this.model.portcall.mship.imo = lupaModel.imo;
			this.model.portcall.mship.callsign = lupaModel.callsign;
			this.model.portcall.mship.shipid = lupaModel.shipid;
			this.model.portcall.mship.version = lupaModel.version;
			this.model.portcall.voyagenum = lupaModel.voyagenum;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.portcode = lupaModel.portcode;
			this.model.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
			this.model.departuredate = lupaModel.atd !== null ? lupaModel.atd : lupaModel.etd;
			this.model.portcall.previouscountry = lupaModel.previouscountry;
			this.model.portcall.previousport = lupaModel.previousport;
			this.model.portcall.nextcountry = lupaModel.nextcountry;
			this.model.portcall.nextport = lupaModel.nextport;
			this.model.portcall.etd = lupaModel.etd;
			this.model.portcall.eta = lupaModel.eta;
			this.model.portcall.mship.flag = lupaModel.flag;
			this.model.indarrivaldeparture = '1';
		},
		fillPortCallFields(lupaModel) {
			this.model.portcall.mship.shipname = lupaModel.shipname;
			this.model.portcall.mship.shiptypecode = lupaModel.shiptypecode;
			this.model.portcall.mship.imo = lupaModel.imo;
			this.model.portcall.mship.callsign = lupaModel.callsign;
			this.model.portcall.mship.shipid = lupaModel.shipid;
			this.model.portcall.mship.version = lupaModel.version;
			this.model.portcall.voyagenum = lupaModel.voyagenum;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.portcode = lupaModel.portcode;
			this.model.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
			this.model.departuredate = lupaModel.atd !== null ? lupaModel.atd : lupaModel.etd;
			this.model.portcall.previouscountry = lupaModel.previouscountry;
			this.model.portcall.previousport = lupaModel.previousport;
			this.model.portcall.nextcountry = lupaModel.nextcountry;
			this.model.portcall.nextport = lupaModel.nextport;
			this.model.portcall.etd = lupaModel.etd;
			this.model.portcall.eta = lupaModel.eta;
			this.model.portcall.mship.flag = lupaModel.flag;
		},
		clearPortCallFields() {
			this.model.portcall.mship.shipname = null;
			this.model.portcall.mship.shiptypecode = null;
			this.model.portcall.mship.imo = null;
			this.model.portcall.mship.callsign = null;
			this.model.portcall.voyagenum = null;
			this.model.portcall.mship.shipversion = null;
			this.model.portcall.countryofarrival = null;
			this.model.portcall.countryofdeparture = null;
			this.model.portcall.portofarrival = null;
			this.model.portcall.portofdeparture = null;
			this.model.arrivaldate = null;
			this.model.departuredate = null;

			this.model.portcall.previousport = null;
			this.model.portcall.nextcountry = null;
			this.model.portcall.nextport = null;
			this.model.portcall.etd = null;
			this.model.portcall.eta = null;
		},
		calcularDiferencia() {
			const fechaETD = new Date(this.model.portcall.etd);
			const fechaETA = new Date(this.model.portcall.eta);

			if (!isNaN(fechaETD) && !isNaN(fechaETA)) {
				// Calcula la diferencia en milisegundos
				const diferenciaMilisegundos = fechaETD - fechaETA;
				// Convierte la diferencia a días
				const diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);
				this.diferenciaDias = Math.floor(diferenciaDias);
				this.model.imofalform3.periodstay = this.diferenciaDias;
			} else {
				this.diferenciaDias = 0;
				this.model.imofalform3.periodstay = this.diferenciaDias;
			}
		}
	},
	updated() {
		this.calcularDiferencia();
		//this.model.periodstay = this.diferenciaDias;
	},
	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-imofalform3-portcall');
	}
};
</script>
